<!-- eslint-disable no-console -->
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header-v2";
import appConfig from "@/app.config";
import StarRating from "vue-star-rating";
// import simplebar from "simplebar-vue";
import { required } from "vuelidate/lib/validators";
import api from "../../../../common/axios";
import JobStatus from "@/components/job-status-2";
import PreviewAddress from "@/components/preview-address-detail";
import pdfViewerDocument from "@/components/pdfViewerDocPengiriman";
import sliderDocument from "@/components/sliderDoc";
// import DirectionsRenderer from "@/components/DirectionsRenderer.js";
import GmapCustomMarker from "vue2-gmap-custom-marker";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Lihat Job",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    "gmap-custom-marker": GmapCustomMarker,
    Layout,
    PageHeader,
    StarRating,
    PreviewAddress,
    JobStatus,
    pdfViewerDocument,
    sliderDocument,
    // DirectionsRenderer,
  },
  data() {
    return {
      config: {
        api: "job",
        chat_job: "chat_job",
        setting: "setting",
      },
      title: "Lihat Job",
      items: [
        {
          text: "Manajemen Job",
          href: "/job",
        },
        {
          text: "Lihat Job",
          active: true,
        },
      ],
      chats: {
        message: "",
      },
      model: {
        logistic_company: {},
        addresses: [],
      },
      docPengirimanData: {
        title: null,
        document: {},
        detail: {},
      },

      center: { lat: -6.2, lng: 106.816666 },
      startLocation: null,
      endLocation: null,
      markers: [],

      submitform: false,
      chatMessages: [
        {
          id: 1,
          image: require("@/assets/images/users/user-2.jpg"),
          name: "John Deo",
          message: "Hello!",
          time: "10:00",
        },
        {
          id: 2,
          image: require("@/assets/images/users/user-3.jpg"),
          name: "Smith",
          message: "Hi, How are you? What about our next meeting?",
          time: "10:01",
          odd: true,
        },
        {
          id: 3,
          image: require("@/assets/images/users/user-2.jpg"),
          name: "John Deo",
          message: "Yeah everything is fine",
          time: "10:01",
        },
        {
          id: 4,
          image: require("@/assets/images/users/user-3.jpg"),
          name: "Smith",
          message: "Wow that's great",
          time: "10:02",
          odd: true,
        },
        {
          id: 5,
          image: require("@/assets/images/users/user-2.jpg"),
          name: "John Deo",
          message: "Yup!",
          time: "10:03",
        },
      ],
      setting: {},
      negoChat: [],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      badge: "AEX01823123",
      offers: {},
      cancel_reason: null,
      tracks: {},
      waypnt: [],
      userDetailNego: {},
      previewPath: null,
      messageValidation: false,
    };
  },
  validations: {
    chats: {
      message: { required },
    },
  },
  computed: {
    refund() {
      const job =
        this.model.offer_price !== undefined ? this.model.offer_price : 0;
      const discount = Math.floor((30 / 100) * this.model.offer_price);
      return job - discount;
    },
  },
  mounted() {
    let _ = this;
    _.getData();
    _.getOvvered();
    _.addMarker();
    _.getSetting();
  },
  watch: {
    $route(newVal, oldVal) {
      // TODO: react to navigation event.
      // params cotains the current route parameters
      if (newVal != oldVal) {
        let _ = this;
        _.getData();
        _.getOvvered();
        _.addMarker();
      }
    },
  },
  methods: {
    prevImage(path) {
      this.previewPath = path;
      this.$refs["preview-image"].show();
    },
    findStatus(data) {
      const a = data.find((fruit) => fruit.owner == "admin");

      return a.status_text;
    },
    showModalReject() {
      this.$refs["show-modal-reject"].show();
    },
    addMarker() {
      this.startLocation = { lat: -6.2, lng: 106.816666 };
      this.endLocation = {
        lat: -6.914744,
        lng: 107.60981,
      };
    },
    handleShown() {
      // new simplebar(this.$refs.simplebars);
      console.log("hallo");
    },
    async liveTracking() {
      let _ = this;

      await api
        .get(_.config.api + "/" + this.$route.params.id + "/tracking")
        .then((response) => {
          this.tracks = response.data.data;
          this.tracks.histories;

          // if (_.tracks.lat && _.tracks.lng) {
          //   _.center.lat = parseFloat(_.tracks.lat);
          //   _.center.lng = parseFloat(_.tracks.lng);
          // }
          this.$refs["open-modal-map"].show();

          let pickup = _.model.addresses.find(function (loc) {
            if (loc.type == "pickup") return true;
          });
          this.markers.push({
            lat: parseFloat(pickup.lat),
            lng: parseFloat(pickup.lng),
          });

          let drop = _.model.addresses.find(function (loc) {
            if (loc.type == "dropoff") return true;
          });

          this.markers.push({
            lat: parseFloat(drop.lat),
            lng: parseFloat(drop.lng),
          });

          if (_.tracks.lat && _.tracks.lng) {
            _.center.lat = parseFloat(_.tracks.lat);
            _.center.lng = parseFloat(_.tracks.lng);

            this.markers.push({
              lat: parseFloat(_.tracks.lat),
              lng: parseFloat(_.tracks.lng),
            });
          } else {
            _.center.lat = parseFloat(pickup.lat);
            _.center.lng = parseFloat(pickup.lng);
          }

          console.log(this.markers);

          // if (_.tracks.lat && _.tracks.lat) {
          //   this.markers.push({
          //     lat: parseFloat(_.tracks.lat),
          //     lng: parseFloat(_.tracks.lng),
          //   });
          //   this.center = {
          //     lat: parseFloat(_.tracks.lat),
          //     lng: parseFloat(_.tracks.lng),
          //   };
          // }

          // this.waypnt.push({
          //   location: {
          //     lat: parseFloat(-6.9082745018),
          //     lng: parseFloat(107.616392992),
          //   },
          //   stopover: true,
          // });
        });

      setTimeout(() => {
        this.startLocation = {
          lat: parseFloat(_.model.addresses[0].lat),
          lng: parseFloat(_.model.addresses[0].lng),
        };
        this.endLocation = {
          lat: parseFloat(_.model.addresses[1].lat),
          lng: parseFloat(_.model.addresses[1].lng),
        };
      }, 3000);
    },
    docPengirimanDetail(type) {
      let _ = this;
      if (type == 1) {
        const detail = _.model.addresses.find(
          (fruit) => fruit.type == "pickup"
        );

        this.docPengirimanData.title = "Dokumen Delivery Order";
        this.docPengirimanData.document = detail.documents;
        this.docPengirimanData.detail = detail;
      } else {
        const detail = _.model.addresses.find(
          (fruit) => fruit.type == "dropoff"
        );

        this.docPengirimanData.title = "Dokumen POD";
        this.docPengirimanData.document = detail.documents;
        this.docPengirimanData.detail = detail;
      }
      this.$refs["show-doc-slider"].show();
    },
    async nego(user_2) {
      let _ = this;
      let id_chat = null;
      await api
        .get(_.config.chat_job, {
          params: {
            job_id: _.model.id,
            user_id_1: _.model.user_id,
            user_id_2: user_2,
          },
        })
        .then((response) => {
          if (response.status !== 404) {
            _.userDetailNego = response.data.data.user_2;
            id_chat = response.data.data.id;
            _.messageValidation = true;
            api
              .get(_.config.chat_job + "/" + id_chat + "?orderby=id&sort=asc")
              .then((response) => {
                _.negoChat = response.data.data.rows;
                this.$refs.modalNegotiation.show();
              });
          }
        })
        .catch(() => {
          this.$refs.modalNegotiation.show();
        });
    },

    docPengiriman() {
      this.$refs["show-doc-pengiriman"].show();
    },
    saveMessage() {},
    getData() {
      let _ = this;
      api.get(_.config.api + "/" + this.$route.params.id).then((response) => {
        _.model = response.data.data;
        this.badge = _.model.code;
      });
    },
    getSetting() {
      let _ = this;
      api.get(_.config.setting).then((response) => {
        _.setting = response.data.data.setting;
        this.badge = _.model.code;
      });
    },
    getOvvered() {
      let _ = this;
      api
        .get(_.config.api + "/" + this.$route.params.id + "/offer", {
          params: {
            page: this.currentPage,
            per_page: this.perPage,
            orderby: "created_at",
            sort: "desc",
          },
        })
        .then((response) => {
          _.offers = response.data.data.rows;
          _.totalRows = response.data.data.total_data;
        });
    },
    cancelJob() {
      let _ = this;
      let data = {
        cancel_reason: this.cancel_reason,
      };
      api
        .put(_.config.api + "/" + this.$route.params.id + "/cancel", data)
        .then(() => {
          _.$toast("Job Telah Ditolak", {
            icon: "fas fa-check",
            type: "success",
          });
          this.$refs["show-modal-reject"].hide();
          _.getData();
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :badge="badge" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div
                class="col-lg-4"
                style="border-right: 1px solid #333; border-radius: 2px"
              >
                <h4 class="mb-3">Detail Job</h4>
                <div class="row">
                  <div class="col-lg-6">
                    <label class="mb-0 form-label font-weight-light">No</label>
                    <p class="font-weight-bolder mb-0">{{ model.code }}</p>
                    <br />
                    <label class="mb-0 form-label font-weight-light"
                      >Tanggal Pickup</label
                    >
                    <p class="font-weight-bolder mb-0">
                      {{ model.start_date | moment("DD MMM YYYY") }}
                    </p>
                    <br />
                    <label class="mb-0 form-label font-weight-light"
                      >Admin Fee</label
                    >
                    <p class="font-weight-bolder mb-0">
                      {{ setting.admin_fee | currency_2 }}
                    </p>
                    <br />

                    <label class="mb-0 form-label font-weight-light"
                      >Status</label
                    >
                    <p class="font-weight-bolder mb-0 text-primary">
                      <JobStatus :status="model.status" />
                    </p>
                    <br />
                  </div>
                  <div class="col-lg-6">
                    <label class="mb-0 form-label font-weight-light"
                      >Tanggal Posting</label
                    >
                    <p class="font-weight-bolder mb-0">
                      {{ model.start_date | moment("DD MMM YYYY") }}
                    </p>
                    <br />
                    <label class="mb-0 form-label font-weight-light"
                      >Estimasi Sampai</label
                    >
                    <p class="font-weight-bolder mb-0">
                      {{ model.end_date | moment("DD MMM YYYY HH:mm") }}
                    </p>
                    <br />
                    <label class="mb-0 form-label font-weight-light"
                      >Deal Harga</label
                    >
                    <p class="font-weight-bolder mb-0">
                      {{ model.offer_price | currency_2 }}
                    </p>
                    <br />

                    <label class="mb-0 form-label font-weight-light"
                      >Total Harga</label
                    >
                    <p class="font-weight-bolder mb-0">
                      {{
                        (Number(model.offer_price) + Number(setting.admin_fee))
                          | currency_2
                      }}
                    </p>
                    <br />
                  </div>

                  <div class="col-lg-6" v-if="model.status == 12">
                    <label class="mb-0 form-label font-weight-light"
                      >Pengembalian Dana</label
                    >
                    <p
                      class="font-weight-bolder mb-0"
                      v-if="model.cancel_at >= 7"
                    >
                      {{ refund | currency_2 }}
                    </p>
                    <p
                      class="font-weight-bolder mb-0"
                      v-if="model.cancel_by.role_id > 3 || model.cancel_at <= 6"
                    >
                      {{
                        (model.offer_price
                          ? model.offer_price
                          : 0 - model.total_payment) | currency_2
                      }}
                    </p>
                    <br />
                  </div>

                  <div class="col-lg-6" v-if="model.status == 12">
                    <label class="mb-0 form-label font-weight-light"
                      >Alasan Pembatalan</label
                    >
                    <p class="font-weight-bolder mb-0">
                      {{ model.cancel_reason }}
                    </p>
                    <br />
                  </div>

                  <div class="col-lg-12" v-if="model.status == 3">
                    <label class="mb-0 form-label font-weight-light"
                      >Dimohon segera melakukan pembayaran sebelum</label
                    >
                    <p class="font-weight-bolder mb-0">
                      {{ model.start_date | moment("DD MMMM Y hh:ss") }}
                    </p>
                  </div>

                  <div class="col-lg-12">
                    <template
                      v-if="
                        model.additional_price !== null &&
                        model.additional_price > 0
                      "
                    >
                      <label class="mb-0 form-label font-weight-light"
                        >Biaya Tambahan</label
                      >
                      <p class="font-weight-bolder mb-0">
                        {{ model.additional_price | currency_2 }}
                      </p>
                      <br />
                      <template v-if="model.additional_price_paid === false">
                        <label
                          class="mb-0 form-label font-weight-light text-danger"
                        >
                          Driver melakukan pengajuan penambahan biaya
                        </label>
                        <br />
                        <b-link
                          class="text-danger font-weight-bolder"
                          @click="$refs.showAditionalPrice.show()"
                        >
                          <u>Lihat detail pengajuan</u>
                        </b-link>
                        <br /><br />
                      </template>
                    </template>
                  </div>

                  <div
                    class="col-lg-12 mb-2"
                    v-if="
                      model.status >= 5 &&
                      model.status < 12 &&
                      model.documents != null
                    "
                  >
                    <b-button
                      @click="docPengiriman"
                      size="sm"
                      variant="secondary"
                      class="mr-2"
                      >Lihat Dokumen Pengiriman</b-button
                    >
                  </div>

                  <div class="col-md-12">
                    <b-card
                      bg-variant="light"
                      border-variant="secondary"
                      class="shadow-lg"
                    >
                      <b-card-text>
                        <div class="mb-3">
                          <div class="row">
                            <div class="col-md-6">
                              <p class="font-weight-medium font-size-16 mb-0">
                                Pickup
                              </p>
                            </div>
                            <div
                              class="col-md-6"
                              v-if="model.status >= 5 && model.status < 12"
                            >
                              <!-- <b-button
                                @click="docPengirimanDetail(1)"
                                size="sm"
                                variant="outline-primary"
                                class="float-right"
                                >Delivery Order</b-button
                              > -->
                            </div>
                          </div>

                          <PreviewAddress
                            :type="'pickup'"
                            :list-address="model.addresses"
                          />
                        </div>
                        <div>
                          <div class="row">
                            <div class="col-md-6">
                              <p class="font-weight-medium mb-0 font-size-16">
                                Dropoff
                              </p>
                            </div>

                            <div
                              class="col-md-6"
                              v-if="model.status >= 10 && model.status < 12"
                            >
                              <!-- <b-button
                                @click="docPengirimanDetail(2)"
                                size="sm"
                                variant="outline-primary"
                                class="float-right"
                                >POD</b-button
                              > -->
                            </div>
                          </div>
                          <PreviewAddress
                            :type="'dropoff'"
                            :list-address="model.addresses"
                          />
                        </div>
                      </b-card-text>
                    </b-card>
                    <h4 class="mb-3">Detail Paket</h4>
                    <div class="row">
                      <div class="col-lg-12">
                        <label class="mb-0 form-label font-weight-light"
                          >Nama</label
                        >
                        <p class="font-weight-bolder mb-0">
                          {{ model.package_name }}
                        </p>
                        <br />
                      </div>
                      <div class="col-lg-4">
                        <label class="mb-0 form-label font-weight-light"
                          >Berat</label
                        >
                        <p class="font-weight-bolder mb-0">
                          {{ model.package_weight }} Kg
                        </p>
                        <br />
                      </div>
                      <div class="col-lg-4">
                        <label class="mb-0 form-label font-weight-light"
                          >Volume</label
                        >
                        <p class="font-weight-bolder mb-0">
                          {{ model.package_volume }} m³
                        </p>
                        <br />
                      </div>
                      <div class="col-lg-4">
                        <label class="mb-0 form-label font-weight-light"
                          >Koli</label
                        >
                        <p class="font-weight-bolder mb-0">
                          {{ model.package_koli }} Pcs
                        </p>
                        <br />
                      </div>
                      <div class="col-lg-12">
                        <label class="mb-0 form-label font-weight-light"
                          >Note</label
                        >
                        <p class="font-weight-bolder mb-0">
                          {{ model.package_note }}
                        </p>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-8">
                <div class="mb-4">
                  <h4 class="mb-3">Logistic Company</h4>
                  <div class="row">
                    <div
                      class="col-lg-6"
                      style="border-right: 1px solid #333; border-radius: 2px"
                    >
                      <h5>{{ model.logistic_company.name }}</h5>
                      <label class="mb-0 mt-0 form-label font-weight-light">{{
                        model.logistic_company.email
                      }}</label>
                      <br />
                      <label class="mb-0 mt-0 form-label font-weight-light">{{
                        model.logistic_company.contact_person
                      }}</label>
                    </div>

                    <div class="col-md-6">
                      <label class="mb-0 mt-0 form-label font-weight-light"
                        >Alamat Pool</label
                      >
                      <h6>{{ model.logistic_company.address }}</h6>

                      <label class="mb-0 mt-0 form-label font-weight-light"
                        >Phone Number</label
                      >
                      <h6>{{ model.logistic_company.phone }}</h6>
                    </div>
                  </div>
                </div>
                <!-- v-if="model.status <= 2" -->
                <template v-if="model.status <= 2">
                  <h4 class="mb-3">Kandidat Driver</h4>
                  <div id="list-driver pt-2" v-if="offers.length > 0">
                    <b-card
                      class="border border-dark shadow-lg"
                      v-for="(offer, index) in offers"
                      :key="index"
                    >
                      <b-card-text>
                        <div class="d-inline-flex w-100">
                          <div class="avatar">
                            <img
                              :src="offer.owner_detail.profile_picture"
                              alt="male"
                              class="avatar-md rounded-circle mr-2"
                            />
                          </div>
                          <div
                            class="driver-info w-100 ml-3 d-flex justify-content-between"
                          >
                            <div class="info">
                              <p class="mb-0 font-weight-bolder d-inline">
                                {{ offer.owner_detail.name }}
                                <star-rating
                                  :increment="0.5"
                                  :inline="true"
                                  :rating="offer.owner_detail.rating"
                                  :star-size="23"
                                  :read-only="true"
                                  class="ml-3"
                                ></star-rating>
                              </p>
                              <p class="mb-0">
                                {{ offer.owner_detail.address }}
                              </p>
                              <p class="text-primary font-weight-medium">
                                {{ offer.job_this_week }} Job minggu ini
                              </p>
                              <p class="font-weight-bolder mb-0">
                                {{ offer.offer_price | currency_2 }}
                              </p>
                            </div>
                            <div class="action-driver py-4">
                              <b-button
                                type="button"
                                variant="warning"
                                class="text-dark mr-2"
                                @click="nego(offer.user_id)"
                                >Lihat Chat</b-button
                              >
                            </div>
                          </div>
                        </div>
                      </b-card-text>
                    </b-card>
                    <div class="row" v-if="offers.length > 0">
                      <div class="col-lg-12 d-flex justify-content-center">
                        <div class="dataTables_paginate paging_simple_numbers">
                          <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <b-pagination
                              v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="10"
                            ></b-pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-if="model.status > 2">
                  <h4
                    class="mb-3"
                    v-if="
                      model.truck_owner_id !== null &&
                      model.truck_owner_id !== 0
                    "
                  >
                    Driver Terpilih
                  </h4>
                  <div
                    class="row"
                    v-if="
                      model.truck_owner_id !== null &&
                      model.truck_owner_id !== 0
                    "
                  >
                    <!-- <div class="col-lg-12">
                      <h5>Truck Owner</h5>
                    </div> -->
                    <div
                      class="col-lg-6 mb-3"
                      style="border-right: 1px solid #333; border-radius: 2px"
                    >
                      <h5>Truck Owner - {{ model.truck_owner.name }}</h5>
                      <label class="mb-0 mt-0 form-label font-weight-light">{{
                        model.truck_owner.address
                      }}</label>
                      <br />
                      <label class="mb-0 mt-0 form-label font-weight-light">{{
                        model.truck_owner.email
                      }}</label>
                      <br />
                      <!-- <label class="mb-0 mt-0 form-label font-weight-light">{{
                        model.truck_owner.phone
                      }}</label> -->
                    </div>

                    <div class="col-md-6 mb-3">
                      <label class="mb-0 mt-0 form-label font-weight-light"
                        >Alamat Pool</label
                      >
                      <h6>{{ model.truck_owner.pool_address }}</h6>

                      <label class="mb-0 mt-0 form-label font-weight-light"
                        >Telepon</label
                      >
                      <h6>{{ model.truck_owner.phone }}</h6>
                    </div>
                  </div>

                  <div class="row">
                    <div
                      class="col-lg-6 mb-2"
                      style="border-right: 1px solid #333; border-radius: 2px"
                    >
                      <h5>Driver</h5>
                      <div v-if="model.status == 4">
                        <p><i>Menunggu dipilih oleh truck owner</i></p>
                      </div>
                      <div v-if="model.status > 4">
                        <div class="row">
                          <div class="col-md-6">
                            <label
                              class="mb-0 mt-0 form-label font-weight-light"
                              >No. KTP</label
                            >
                            <h6>
                              {{
                                (model.driver &&
                                  model.driver.ktp != null &&
                                  model.driver.ktp.ktp_no) ||
                                "-"
                              }}
                            </h6>
                            <label
                              class="mb-0 mt-0 form-label font-weight-light"
                              >No. SIM</label
                            >
                            <h6>
                              {{
                                (model.driver &&
                                  model.driver.sim != null &&
                                  model.driver.sim.sim_no) ||
                                "-"
                              }}
                            </h6>
                          </div>

                          <div class="col-md-6">
                            <label
                              class="mb-0 mt-0 form-label font-weight-light"
                              >Name</label
                            >
                            <h6>
                              {{ model.driver ? model.driver.name : "-" }}
                            </h6>
                            <label
                              class="mb-0 mt-0 form-label font-weight-light"
                              >Address</label
                            >
                            <h6>
                              {{ model.driver ? model.driver.address : "-" }}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 mb-2">
                      <h5>Armada</h5>
                      <div v-if="model.status == 4">
                        <p><i>Menunggu dipilih oleh truck owner</i></p>
                      </div>
                      <div v-if="model.status > 4">
                        <div class="row">
                          <div class="col-md-6">
                            <label
                              class="mb-0 mt-0 form-label font-weight-light"
                              >No. STNK</label
                            >
                            <h6>
                              {{
                                model.armada ? model.armada.stnk_number : "-"
                              }}
                            </h6>
                            <label
                              class="mb-0 mt-0 form-label font-weight-light"
                              >Merek</label
                            >
                            <h6>
                              {{ model.armada ? model.armada.merk : "-" }}
                            </h6>

                            <label
                              class="mb-0 mt-0 form-label font-weight-light"
                              >Warna
                            </label>
                            <h6>
                              {{ model.armada ? model.armada.color : "-" }}
                            </h6>
                          </div>

                          <div class="col-md-6">
                            <label
                              class="mb-0 mt-0 form-label font-weight-light"
                              >No Plat</label
                            >
                            <h6>
                              {{
                                model.armada ? model.armada.plat_number : "-"
                              }}
                            </h6>
                            <label
                              class="mb-0 mt-0 form-label font-weight-light"
                              >Type</label
                            >
                            <h6>
                              {{ model.armada ? model.armada.type : "-" }}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="action">
                  <b-button
                    v-if="model.status != 11 && model.status != 12"
                    type="button"
                    variant="danger"
                    @click="showModalReject"
                    class="mr-2"
                    >Batal</b-button
                  >

                  <b-button
                    v-if="model.status >= 5 && model.status < 12"
                    style="
                      background-color: #025ffd;
                      color: white;
                      border: none;
                    "
                    class="mr-2"
                    @click="liveTracking()"
                    >Tracking</b-button
                  >

                  <b-button to="/job" variant="light">Kembali</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal negosiasi-->
    <b-modal
      id="modal-negotiation"
      ref="modalNegotiation"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      @shown="handleShown"
    >
      <template #modal-title>
        <div v-if="messageValidation == true" class="d-inline-flex w-100">
          <div class="avatar">
            <img
              :src="userDetailNego.profile_picture"
              alt="male"
              class="avatar-sm rounded-circle mr-1"
            />
          </div>
          <div class="driver-info w-100 ml-3 d-flex justify-content-between">
            <div class="info">
              <p class="mb-0 font-weight-bolder d-inline">
                {{ userDetailNego.full_name }}
              </p>
            </div>
          </div>
        </div>

        <div v-else class="text-center">
          <p class="mb-0 font-weight-bolder d-inline">Tidak ada obrolan</p>
        </div>
      </template>
      <div class="chat-conversation">
        <p></p>
        <ul class="conversation-list">
          <div style="max-height: 367px">
            <li
              v-for="chat in negoChat"
              :key="chat.id"
              :class="{ odd: chat.sender_id !== userDetailNego.id }"
              class="clearfix"
            >
              <div class="conversation-text">
                <div class="ctext-wrap">
                  <p>{{ chat.message }}</p>
                  <b-img
                    v-if="chat.file_path"
                    width="70"
                    height="48"
                    rounded
                    :src="chat.file_path"
                    @click="prevImage(chat.file_path)"
                  />
                  <p class="time">{{ chat.created_at | moment("hh:mm") }}</p>
                </div>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </b-modal>
    <!-- /.modal -->

    <!-- modal 2 Lihat Doc Pengiriman-->
    <b-modal
      id="modal-center"
      centered
      ref="show-doc-pengiriman"
      size="lg"
      title="Dokumen Pengiriman oleh LC"
      title-class="font-18"
      hide-footer
    >
      <div class="modal-body">
        <div class="row d-flex justify-content-start">
          <div
            class="mx-1 my-2 border border-primary"
            style="max-width: 181px"
            v-for="(i, index) in model.documents"
            :key="index"
          >
            <div class="row">
              <div class="col-md-12">
                <pdfViewerDocument :file="i.file_path" />
              </div>
              <div class="col-md-12">
                <hr />
                <h6 class="p-1 text-center">Dokumen {{ index + 1 }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- POR -->
    <b-modal
      id="modal-center"
      centered
      ref="show-doc-slider"
      size="lg"
      min-height="100px;"
      :title="docPengirimanData.title"
      title-class="font-18"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 border-primary">
            <sliderDocument :detail="docPengirimanData"></sliderDocument>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="preview-image"
      centered
      ref="preview-image"
      hide-title=""
      hide-footer
    >
      <div class="modal-body">
        <div class="row d-flex justify-content-center">
          <img :src="previewPath" width="500px" alt="" />
        </div>
      </div>
    </b-modal>

    <!-- Tracking -->
    <b-modal
      id="modal-center"
      centered
      ref="open-modal-map"
      size="xl"
      min-height="100px;"
      title="Live Tracking"
      title-class="font-18"
      hide-footer
    >
      <div class="modal-bod p-0y">
        <div class="row">
          <div class="col-md-9" id="map_canvas">
            <GmapMap
              v-if="markers.length > 0"
              :center="center"
              :zoom="12"
              style="width: 100%; height: 570px"
            >
              <gmap-custom-marker
                v-for="(item, index) in markers"
                :marker="item"
                :key="index"
              >
                <img
                  v-show="index == 0"
                  src="http://maps.google.com/mapfiles/ms/icons/red-dot.png"
                  alt
                  width="45px"
                />
                <img
                  v-show="index == 1"
                  src="http://maps.google.com/mapfiles/ms/icons/green-dot.png"
                  alt
                  width="45px"
                />
                <img
                  v-show="index == 2"
                  src="@/assets/images/pin2.png"
                  alt
                  width="38px"
                />
              </gmap-custom-marker>
              <!-- <DirectionsRenderer
                ref="mapsDirection"
                travelMode="DRIVING"
                :origin="startLocation"
                :destination="endLocation"
              /> -->

              <!-- <DirectionsRenderer
                ref="mapsDirection"
                travelMode="DRIVING"
                :origin="startLocation"
                :destination="endLocation"
                :waypoints="waypnt"
                :optimizeWaypoints="true"
              /> -->
            </GmapMap>

            <!-- <GmapMap
              :center="center"
              :zoom="13"
              style="width: 100%; height: 570px"
            >
              // eslint-disable-next-line vue/valid-v-bind
              <GmapMarker
                :position="center"
                @click="center"
                :icon="require('@/assets/images/pin2.png')"
              />
            </GmapMap> -->
          </div>

          <div class="col md-3 border-left border-primary">
            <!-- <b-button
              @click="addMarker"
              size="sm"
              variant="secondary"
              class="mr-2"
              >Start Direction</b-button
            > -->
            <div class="row">
              <div class="col-md-12">
                <!-- <div v-for="(i, x) in tracks.histories" :key="x">
                  <p
                    class="font-weight-bolder mb-0"
                    :class="{
                      'text-danger': x == 0,
                    }"
                  >
                    {{ findStatus(i.status_text) }}
                  </p>
                  <p class="mb-0 mt-0 font-size-12">
                    {{ i.created_at | moment("D MMMM YYYY - hh:mm") }}
                  </p>
                </div> -->

                <!-- <div class="row">
                  <div class="col-md-12">
                    <ul class="timeline">
                      <li v-for="(i, x) in tracks.histories" :key="x">
                        <p class="font-weight-bolder font-size-13 mb-0">
                          {{ findStatus(i.status_text) }}
                        </p>
                        <p class="mb-3 mt-0 font-size-11">
                          {{ i.created_at | moment("D MMMM YYYY - hh:mm") }}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div> -->

                <ol class="activity-feed">
                  <li
                    class="feed-item"
                    v-for="(i, x) in tracks.histories"
                    :key="x"
                  >
                    <div class="feed-item-list">
                      <span
                        class="date text-capitalize font-weight-bolder font-size-13"
                        :class="{
                          'text-danger': x == 0,
                        }"
                        >{{ findStatus(i.status_text) }}</span
                      >
                      <span class="activity-text font-size-12">
                        {{ i.created_at | moment("D MMMM YYYY - hh:mm") }}</span
                      >
                    </div>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      ref="show-modal-reject"
      size="lg"
      title="Cancel Job"
      title-class="font-18"
      hide-footer
    >
      <div class="modal-body">
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form
            @submit.prevent="handleSubmit(cancelJob)"
            class="form-horizontal"
          >
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  name="Alasan Cancel"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    id="input-group-2"
                    label="Alasan Tolak"
                    label-for="input"
                  >
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="4"
                      v-model="cancel_reason"
                    ></textarea>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </div>
              <div class="col-md-12">
                <div class="form-group row">
                  <div class="col-sm-3">
                    <b-button type="submit" variant="primary" class="w-100"
                      >Tolak</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </ValidationObserver>
      </div>
    </b-modal>

    <!-- additional_price -->
    <b-modal
      id="modal-center"
      centered
      ref="showAditionalPrice"
      size="lg"
      title="Permintaan Tambahan Biaya"
      title-class="font-18"
      hide-footer
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-3">
            <label class="mb-0 form-label font-weight-light"
              >Tambahan Biaya</label
            >
            <p class="font-weight-bolder mb-0" v-if="model.additional_price">
              {{ model.additional_price | currency_2 }}
            </p>
            <br />
          </div>
          <div class="col-lg-3">
            <label class="mb-0 form-label font-weight-light">Keterangan</label>
            <p class="font-weight-bolder mb-0">-</p>
            <br />
          </div>
        </div>
        <div class="row" v-if="model.invoice_path">
          <div class="col-lg-12 text-center">
            <b-link target="_blank" :href="model.invoice_path">
              <b-img
                fluid
                style="max-height: 400px"
                :src="model.invoice_path"
              />
            </b-link>
          </div>
        </div>

        <div class="text-right mt-3">
          <b-button
            type="button"
            class="ml-2 btn-light"
            @click="$refs.showAditionalPrice.hide()"
          >
            Kembali
          </b-button>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>

<style lanng="scss" scoped>
.action {
  position: absolute;
  bottom: 0;
  right: 15px;
}

.conversation-list {
  list-style: none;
  padding: 0px 10px;
  max-height: 350px;
}

.timeline {
  padding-left: 15px;
  padding-right: 2px;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: "";
  background: #f01b3c;
  display: inline-block;
  position: absolute;
  left: 8.5px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 1px 0;
  padding-left: 15px;
}
ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #f01b3c;
  left: 2px;
  width: 15px;
  height: 15px;
  z-index: 400;
}
</style>

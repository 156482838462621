<!-- eslint-disable no-console -->
<template>
  <div>
    <div class="content-slider border-radius d-flex justify-content-center">
      <i
        class="fas fa-arrow-alt-circle-left font-size-24 nav-kiri"
        @click="$refs.slider.prev()"
      ></i>
      <VueSlickCarousel
        v-if="length > 0"
        ref="slider"
        class="w-75"
        :accessibility="true"
        :arrows="true"
        :infinite="true"
        :slidesToShow="4"
      >
        <div
          class="col-sm-11 my-2 border border-primary"
          v-for="(i, index) in length"
          :key="index"
        >
          <div class="row">
            <div class="col-md-12 border-bottom border-primary">
              <div v-if="documentPreview[index].ext == 'pdf'">
                <pdf
                  :src="documentPreview[index].file"
                  class="pdf-clases"
                ></pdf>
              </div>
              <div v-else>
                <img
                  :src="documentPreview[index].file"
                  alt=""
                  class="embed-classes"
                />
              </div>
            </div>
            <div class="col-md-12">
              <h6 class="p-1 text-center">Dokumen {{ index + 1 }}</h6>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
      <i
        class="fas fa-arrow-alt-circle-right font-size-24 nav-kanan"
        @click="$refs.slider.next()"
      ></i>
    </div>

    <div class="row mt-3">
      <div class="col-md-12">
        <h5>Informasi Checker</h5>
      </div>
      <div class="col-md-2">
        <label class="mb-0 form-label font-weight-light">Name</label>
        <p class="font-weight-bolder mb-0">{{ detail.detail.name }}</p>
        <br />
      </div>
      <div class="col-md-2">
        <label class="mb-0 form-label font-weight-light">Telepon</label>
        <p class="font-weight-bolder mb-0">{{ detail.detail.phone_number }}</p>
        <br />
      </div>
      <div class="col-md-8">
        <label class="mb-0 form-label font-weight-light">Alamat</label>
        <p class="font-weight-bolder mb-0">{{ detail.detail.address }}</p>
        <br />
      </div>
    </div>
  </div>
</template>

<style scoped>
.icon-panah {
  position: absolute;
  top: 35%;
  left: 0%;
}
.content-slider {
  position: relative;
}
.nav-kiri {
  cursor: pointer;
  position: absolute;
  top: 40%;
  left: 5%;
}
.nav-kanan {
  cursor: pointer;
  position: absolute;
  top: 40%;
  right: 5%;
}
</style>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import pdf from "vue-pdf";

export default {
  name: "sliderDocument",
  components: { VueSlickCarousel, pdf },
  data() {
    return {
      documentPreview: [],
      length: 0,
    };
  },

  props: {
    detail: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.detail.detail.documents.forEach((element) => {
      this.documentPreview.push({
        file: element.path,
        ext: element.path.split(".").pop(),
      });
    });

    // eslint-disable-next-line no-console
    // console.log(this.documentPreview);
    this.length = this.documentPreview.length;
  },
  computed: {},
};
</script>

<template>
  <div>
    <p class="font-weight-bolder font-size-15 mb-0">
      {{ addressToShow.name }}
    </p>
    <p class="font-weight-small mb-0">
      {{ addressToShow.address }}
    </p>
    <h6 class="font-size-12 secondary">
      {{ addressToShow.address_detail }}
    </h6>
  </div>
</template>

<script>
export default {
  name: "PreviewAddress",
  props: {
    type: {
      type: String,
      required: true,
    },
    listAddress: {
      type: Array,
      required: true,
    },
  },
  computed: {
    addressToShow() {
      const filtered = this.listAddress.filter(
        (item) => item.type === this.type
      );
      if (this.type === "pickup") {
        return filtered[0];
      } else {
        return filtered.pop();
      }
    },
  },
};
</script>

<template>
  <div>
    <div v-if="ext == 'pdf'">
      <pdf :src="file" class="pdf-clases"></pdf>
      <!-- <pdf
        src="https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf"
      ></pdf> -->
    </div>
    <div v-else>
      <img :src="file" alt="" class="embed-classes" />
    </div>
  </div>
</template>

<style scoped>
.pdf-clases {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}
</style>
<script>
import pdf from "vue-pdf";
export default {
  name: "pdfViewrDocPengiriman",
  components: { pdf },
  data() {
    return {
      ext: null,
    };
  },
  props: {
    file: {
      type: String,
      required: true,
    },
  },
  mounted() {
    if (this.file) {
      this.ext = this.file.split(".").pop();
    }
  },
  computed: {},
};
</script>
